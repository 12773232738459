import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import FacultyList from './FacultyList';

export default class Faculty extends React.Component {
  render() {
    return (
      <div>
        <Breadcrumb tag="nav" style={{ padding: '20px 0' }}>
          <Breadcrumb.Item>
            <Link to="/dashboard">Hippo Admin</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/accounts">Accounts</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/accounts/faculty">Faculty</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />
        <div className="section-header">
          <h5>Faculty</h5>
        </div>
        <FacultyList />
      </div>
    );
  }
}
