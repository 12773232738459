import React from 'react';

import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { notify } from 'react-notify-toast';

import SingleImageInput from '../../../components/Upload/SingleImageInput';
import * as API from '../../../API';

function FacultyImage({ account, image, onImageChange }) {
  if (image?.attributes?.url) {
    return (
      <div>
        <div>
          <img src={image.attributes.url} alt="" style={{ maxWidth: 150 }} />
        </div>
        <div>
          <Button
            type="danger"
            size="small"
            icon={<DeleteOutlined />}
            onClick={async () => {
              try {
                await API.account.update({
                  id: account.id,
                  relationships: { image: { data: null } }
                });
                await API.image.delete({ id: image.id });
                onImageChange(null);
              } catch (err) {
                notify.show(err.message, 'error');
              }
            }}
          >
            Remove
          </Button>
        </div>
      </div>
    );
  }

  return (
    <SingleImageInput
      name="avatar"
      onChange={async image => {
        try {
          await API.account.update({
            id: account.id,
            relationships: {
              image: {
                data: {
                  type: 'image',
                  id: image.id
                }
              }
            }
          });
          onImageChange(image);
        } catch (err) {
          notify.show(err.message, 'error');
        }
      }}
      className="faculty-member__photo"
      path="faculty/photo"
    />
  );
}

export default FacultyImage;
